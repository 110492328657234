<template>
  <!-- <Header type="regular" /> -->

  <!--  -->
  <div class="layout">
    <!-- <HomeHero /> -->

    <div class="container">
      <div class="row position-relative">
        <Subheader type="third-party" />
        <div class="col-12 col-md-12 mb-5 pt-5 content-wrapper">
          <div v-if="!companies">LOADING....</div>
          <div v-if="Object.keys(companies).length > 0" class="row">
            <div
              class="col-12"
              v-if="
                !noCompanies &&
                (chosenCompany >= 0 || chosenCompany === 'all') &&
                metamaskAddress
              "
            >
              <h2 class="mb-5 mt-4">
                {{
                  chosenCompany === "all"
                    ? "All companies"
                    : companies[chosenCompany].companyName
                }}
              </h2>
            </div>
          </div>
          <div class="token-balances-wrapper row">
            <div v-if="!Object.keys(balances).length">
              <p>
                Oops, there don't seem to be any tokens listed under the chosen
                company
              </p>
            </div>
            <div
              :class="
                chosenCompany === 'all' ||
                chosenToken === balance.tokenAddress ||
                (companies[chosenCompany].tokens[balance.tokenAddress] &&
                  !chosenToken)
                  ? 'd-block'
                  : 'd-none'
              "
              class="col-12 col-md-6 col-lg-4"
              :key="i"
              v-for="(balance, i) of balances"
            >
              <div class="token-card">
                <h5 class="mb-3 bold">{{ balance.tokenName }}</h5>
                <h3 class="mt-2 mb-4">
                  <img :src="btcIcon" alt="" />
                  <span class="d-inline-block mx-2">{{ balance.balance }}</span>
                  <small>{{ balance.tokenSymbol }}</small>
                </h3>
                <div class="d-flex buttons-wrapper mt-1">
                  <button
                    @click="setSendToken(balance.tokenSymbol)"
                    class="button white"
                  >
                    Send
                  </button>
                  <button
                    @click="setReceiveToken(balance.tokenSymbol)"
                    class="button white"
                  >
                    Receive
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              mt-5
              d-flex
              align-items-center
              flex-wrap
            "
          >
            <h4
              ref="transactions-history-title"
              class="bold section-heading transactions-heading"
              v-if="!noCompanies"
            >
              Transactions History
            </h4>
            <div class="filters-wrapper d-flex justify-content-end">
              <select v-model="filters.time" class="ms-1 me-2">
                <option selected :value="'all-time'">All time</option>
                <option value="last-day">Today</option>
                <option value="last-week">Last week</option>
                <option value="last-month">Last month</option>
              </select>
              <select v-model="filters.type" class="ms-1">
                <option selected :value="'all'">All types</option>
                <option value="send">Sent</option>
                <option value="receive">Received</option>
              </select>
            </div>
          </div>
          <div class="loader-wrapper" v-if="transactionsLoading">
            <div class="loader"></div>
          </div>
          <div
            class="mt-5 d-flex justify-content-center"
            v-else-if="!tokenTransactions?.length"
          >
            No transactions.
          </div>
          <template v-else>
            <div>
              <div
                class="token-transaction"
                :key="index"
                v-for="(transaction, index) of tokenTransactions"
              >
                <div class="transaction-section d-flex align-items-center">
                  <img class="me-2" :src="clockIcon" alt="" /><span>{{
                    transaction.created_at.replace("T", " ").slice(0, 19)
                  }}</span>
                </div>
                <div class="transaction-section d-flex align-items-center">
                  <span>{{
                    transaction.to_address.toLowerCase() ===
                    metamaskAddress.toLowerCase()
                      ? "Received"
                      : "Sent"
                  }}</span>
                </div>
                <div
                  class="transaction-section from-to d-flex align-items-center"
                >
                  <span
                    class="transaction-address"
                    :title="transaction.from_address"
                    >{{ shortenAddress(transaction.from_address, 7) }}</span
                  >
                  <img class="mx-3" :src="arrowRight2" alt="" />
                  <span
                    class="transaction-address"
                    :title="transaction.to_address"
                    >{{ shortenAddress(transaction.to_address, 7) }}</span
                  >
                </div>
                <div class="transaction-section d-flex align-items-center">
                  <img class="me-1" :src="fiveIcon" alt="" />
                  <span
                    v-if="companies[chosenCompany]?.tokens[transaction.address]"
                    >{{ transaction.amount }}
                    {{
                      companies[chosenCompany]?.tokens[transaction.address]
                        ?.tokenSymbol
                    }}</span
                  >
                </div>
                <div class="transaction-section d-flex align-items-center">
                  <a
                    class="button white py-2"
                    :href="`https://kovan.etherscan.io/tx/${transaction.transaction_hash}`"
                  >
                    Details
                  </a>
                </div>
              </div>
            </div>
            <div
              class="
                pagination-wrapper
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <button
                :disabled="currentPage === 1"
                @click="getTransactions(firstPage, true)"
                class="button white"
              >
                &lt;&lt; <span>First</span>
              </button>
              <button
                :disabled="!prevPage"
                @click="prevPage && getTransactions(Number(prevPage), true)"
                class="button white"
              >
                &lt;<span>Previous</span>
              </button>
              <span class="pagination-current"
                >{{ currentPage }} / {{ lastPage }}</span
              >
              <button
                :disabled="!nextPage"
                @click="nextPage && getTransactions(Number(nextPage), true)"
                class="button white"
              >
                <span>Next</span> &gt;
              </button>
              <button
                :disabled="lastPage == currentPage"
                @click="lastPage && getTransactions(Number(lastPage), true)"
                class="button white"
              >
                <span>Last</span> &gt;&gt;
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div
    @click="closeModal"
    :class="showSendModal || showReceiveModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <div
      @click.stop
      :class="showSendModal && 'active'"
      class="modal position-relative"
    >
      <div @click="closeModal" class="close-modal">×</div>
      <div v-if="metamaskAddress" class="modal-content">
        <h4 class="text-center mb-4 mt-3">Send</h4>
        <p class="text-center modal-text">
          Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi
          ultrices diam sollicitudin condimentum integer ultrices diam
          suspendisse rhoncus.
        </p>
        <label for="amount">Token</label>
        <select
          v-if="companies && companies[chosenCompany]"
          class="mb-3"
          v-model="selectedSendToken"
          name="select-currency"
        >
          <option class="d-none" :selected="true" value="">
            Select a token
          </option>
          <template
            :key="token"
            v-for="token in companies[chosenCompany].tokens"
            :value="selectedSendToken"
          >
            <option :value="token.tokenSymbol">{{ token.tokenName }}</option>
          </template>
        </select>
        <label for="address">Address</label>
        <input
          class="mb-3"
          id="address"
          placeholder="Address"
          v-model="receiverAddress"
          type="text"
        />
        <label for="amount">Amount</label>
        <input
          class="mb-3"
          id="amount"
          placeholder="Enter amount"
          v-model="sendAmount"
          type="number"
        />
        <button
          :disabled="!sendAmount || !receiverAddress"
          type="button"
          class="button mt-3 w-100"
          @click="handleSend"
        >
          Send
        </button>
      </div>
      <div class="w-100" v-else>
        <h4 class="text-center mb-4">Send</h4>
        <button @click="metamaskSetup" class="button w-100">
          Connect wallet
        </button>
      </div>
    </div>

    <div
      @click.stop
      :class="showReceiveModal && 'active'"
      class="modal position-relative"
    >
      <div @click="closeModal" class="close-modal">×</div>
      <div v-if="metamaskAddress" class="modal-content">
        <h4 class="text-center">Receive</h4>
        <p class="modal-text text-center mb-0">
          Nunc, tempus egestas fames cursus tincidunt pulvinar fermentum mi
          ultrices diam sollicitudin condimentum integer ultrices diam
          suspendisse rhoncus.
        </p>
        <img :src="qrUrl" class="mt-4 mb-2" alt="" />
        <label for="address">Address</label>
        <input class="mb-4" disabled :value="metamaskAddress" type="text" />
        <button @click="copyAddress" class="button w-100 mt-3">Copy</button>
      </div>
      <div class="w-100" v-else>
        <h4 class="text-center mb-4">Receive</h4>
        <button @click="metamaskSetup" class="button w-100">
          Connect wallet
        </button>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { defineComponent } from "vue";
import Subheader from "../../components/Subheader.vue";
import QRCode from 'qrcode';
import { shortenAddress, getAmountFromInput } from '../../helpers.js';
import config from '../../config.js';
import { metamaskSetup, getThirdPartyAccounts, getThirdPartyAccountInfo, thirdPartySend } from '../../main.ts';
import "vue3-carousel/dist/carousel.css";
import arrowRight from '../../assets/images/arrow-right.svg';
import arrowRight2 from '../../assets/images/arrow-right2.svg';
import btcIcon from '../../assets/images/btc.svg';
import clockIcon from '../../assets/images/clock.svg';
import fiveIcon from '../../assets/images/5.svg';
  
export default defineComponent({
    name: "Home",
    components: {
        Subheader,
    },
    data() {
        return {
            unsubscribe: null,
            btcIcon: btcIcon,
            clockIcon: clockIcon,
            fiveIcon: fiveIcon,
            metamaskAddress: "",
            networkId: "",
            chainId: "",
            networkName: "",
            totalBalance: "",
            fauBalance: "",
            transactionId: 'testtesttest',
            showReceive: false,
            receiverAddress: "",
            selectedSendToken: "",
            sendAmount: "",
            showSendModal: false,
            showReceiveModal: false,
            showWalletModal: true,
            qrUrl: '',
            chosenCompany: 0,
            chosenToken: null,
            accessToken: null,
            currencies:[],
            transactionTypes:[],
            type:'',
            currency_code:'',
            page:1,
            tokens: null,
            noCompanies: false,
            pagination: {
                total: 0,
                per_page: 10, // required
            },
            start_date : null,
            end_date : null,
            tokenTransactions: [],
            arrowRight: arrowRight,
            arrowRight2: arrowRight2,
            companyBalances: null,
            companies: {},
            balances: {},
            firstLoad: true,
            filters: {
                time: 'all-time',
                type: 'all',
                token: 'all'
            },
            currentPage: 1,
            nextPage: null,
            prevPage: null,
            lastPage: null,
            firstPage: null,
            transactionsLoading: true
        };
    },
    methods: {
        async getThirdPartyAccounts(companyId = 0) {
          let thirdPartyAccounts = await getThirdPartyAccounts(companyId);
          this.$store.dispatch('setThirdPartyAccounts', thirdPartyAccounts);
        },
        getAmountFromInput(input) {
            return getAmountFromInput(input);
        },
        setSendToken(currencyCode) {
            this.selectedSendToken = currencyCode;
            this.showSendModal = true;
        },
        setReceiveToken(currencyCode) {
          this.selectedReceiveToken = currencyCode;
          this.showReceiveModal = true;
        },
        handleSend() {
            thirdPartySend(this.$store.state.chosenThirdPartyAccount, this.sendAmount, this.receiverAddress);
        },
        shortenAddress(string, chars) {
            return shortenAddress(string, chars);
        },
        metamaskSetup() {
            metamaskSetup();
        },
        closeModal() {
            this.showSendModal = false;
            this.showReceiveModal = false;
            this.showWalletModal = false;
        },
        copyAddress() {
            navigator.clipboard.writeText(this.metamaskAddress);
            this.$toast.success('Copied address!');
        },
        setFilter(type, event) {
          this.filters[type] = event.target.value;
        },
        async getTransactions(pageNum = 1, scrollToTop = false) {
            this.tokenTransactions = null;
            this.transactionsLoading = true;
            const tokensArr = [];
            if (this.chosenToken) {
                tokensArr.push(this.chosenToken);
            } else {
                this.companies[this.chosenCompany] &&  Object.keys(this.companies[this.chosenCompany]?.tokens).forEach(tokenAddr => tokensArr.push(tokenAddr));
            }
            try {
                const data = await fetch(`${config.API}/api/v1/token-transactions?page=${pageNum}`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user_address: this.metamaskAddress,
                        type: this.filters.type,
                        from_date: this.filters.time,
                        topic: '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
                        token_addresses: tokensArr
                    })
                })
                .then(res => res.json())
                .catch(error => console.log(error));
                this.tokenTransactions = data.data.token_transactions.data;
                this.prevPage = data.data.token_transactions?.prev_page_url
                    ? Number(data.data.token_transactions.current_page) - 1
                    : null;
                this.nextPage = data.data.token_transactions?.next_page_url
                    ? Number(data.data.token_transactions.current_page) + 1
                    : null;
                this.lastPage = data.data.token_transactions?.last_page;
                this.firstPage = data.data.token_transactions?.last_page;
                this.currentPage = data.data.token_transactions?.current_page;
                this.transactionsLoading = false;
                if (scrollToTop) {
                    setTimeout(() => {
                        const transactionsTitle = this.$refs['transactions-history-title'];
                        const top = transactionsTitle.offsetTop;
                        window.scrollTo(0, top - 300);
                    }, 50);
                }
            } catch(error) {
                console.log(error);
            }
        }
    },
    mounted() {
        this.getThirdPartyAccounts(this.$store.state.chosenCompany || 0);
        this.unsubscribe = this.$store.subscribe(async (mutation, state) => {
            if (mutation.type === 'setChosenCompany') {
                console.log('company id?????', state.chosenCompany);
                this.getThirdPartyAccounts(state.chosenCompany);
                console.log(state.companies);
                if (!state.companies) {
                    this.noCompanies = true;
                } else {
                    const balances = {};
                    Object.keys(state.companies).forEach(companyId => {
                        Object.keys(state.companies[companyId].tokens).forEach(tokenAddr => {
                            if (state.chosenCompany === 'all' || state.chosenCompany == companyId) {
                                balances[tokenAddr] = state.companies[companyId].tokens[tokenAddr];
                            }
                        });
                    });
                    this.balances = balances;
                    this.companies = state.companies;
                    console.log(state.companies);
                }
            }
            if (mutation.type === 'setMetamaskAddress') {
                this.closeModal();
            }
            if (mutation.type === 'setCompanies' && this.firstLoad) {
                this.selectedSendToken = '';
                this.getTransactions();
                this.firstLoad = false;
            }
            if (mutation.type === 'setChosenCompany') {
                this.chosenCompany = await state.chosenCompany;
                this.getTransactions();
            }
            if (mutation.type === 'setChosenThirdPartyAccount') {
                try {
                    const thirdPartyAccInfo = await getThirdPartyAccountInfo(state.chosenThirdPartyAccount);
                    console.log('thirdPartyAccInfo', thirdPartyAccInfo);
                } catch(error) {
                    console.log('error', error);
                }
            }
        });
        this.$store.watch((state) => {
            const {
                metamaskAddress,
                accessToken,
                chosenCompany,
            } = state;
            this.accessToken = accessToken;
            this.chosenCompany = chosenCompany;
            this.chosenToken = state.chosenToken;
            if (state.companies) {
                const balances = {};
                Object.keys(state.companies).forEach(companyId => {
                    Object.keys(state.companies[companyId].tokens).forEach(tokenAddr => {
                        if (state.chosenCompany === 'all' || state.chosenCompany == companyId) {
                            balances[tokenAddr] = state.companies[companyId].tokens[tokenAddr];
                        }
                    });
                });
                this.balances = balances;
                this.companies = state.companies;
                console.log(state.companies);
            }
            if (metamaskAddress) {
                this.metamaskAddress = metamaskAddress;
                QRCode.toDataURL(metamaskAddress, {scale: 8, color: {
                  dark: '#3F4C62'
                }})
                .then((url) => {
                    this.qrUrl = url;
                })
                .catch((err) => {
                    console.error(err);
                });
            } else {
                this.metamaskAddress = metamaskAddress;
            }
        });
        if (this.$store?.state?.companies) {
            this.getTransactions();
        }
    },
    beforeUnmount() {
        this.unsubscribe();
    },
    watch: {
      'filters.time': function() {
        this.getTransactions();
      },
      'filters.type': function() {
        this.getTransactions();
      }
    }
});
</script>
